import { graphql, PageProps } from "gatsby";
import * as React from "react";
import Shell from "../components/_layout/shell";

import christy from "../assets/images/christy-ambrose.jpg";
import alice from "../assets/images/alice-phillips.jpg";

import * as styles from "./about-us.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutUs: React.FC<PageProps<Queries.AboutPageQuery>> = ({ data }) => {
  const pageData = data.prismicAboutPage?.data;
  return (
    <Shell>
      <div className={styles.aboutUsView}>
        <div className="fixed-width">
          <p className="intro">{pageData?.intro}</p>
          <div className={styles.profilesBlock}>
            {pageData?.people?.map((p) => (
              <div>
                <GatsbyImage
                  image={getImage(p!.image!.gatsbyImageData)!}
                  alt={p?.image?.alt || ""}
                />
                <h2>{p?.name}</h2>
                <h3>{p?.title}</h3>
                <p>{p?.copy}</p>
                <a href={p?.email?.url || ""} target="_blank">
                  {p?.email?.url?.split("mailto:")[1]}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query AboutPage {
    prismicAboutPage {
      data {
        intro
        people {
          name
          title
          copy
          image {
            alt
            gatsbyImageData
          }
          email {
            url
          }
        }
      }
    }
  }
`;

export default AboutUs;
